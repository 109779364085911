let config
if (process.env.NODE_ENV == 'production') {
    config = {
        'apiUrl': 'https://api.translate.bullinger-digital.ch/'
        // 'apiUrl': process.env.BASE_URL
    }
}
else {
    config = {
        'apiUrl': 'http://127.0.0.1:8000/'
        // 'apiUrl': window.location.origin
    }
}

export default config
