import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import config from "@/config.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    input: null,
    translation: null,
    original: null,
    file: null,
    fileTranslation: null,
    fileTranslationStatus: null,
    fileDownload: null,
    traceQuery: null,
    traceMatch: null,
    tokenizedSentence: null,
    tokenizedNewText: null,
  },
  mutations: {
    setInput(state, input) {
      state.input = input;
    },

    setTranslation(state, translation) {
      state.translation = translation;
    },

    setOriginal(state, original) {
      state.original = original;
    },

    setFile(state, file) {
      state.file = file;
    },

    setFileTranslation(state, fileTranslation) {
      state.fileTranslation = fileTranslation;
    },

    setFileTranslationStatus(state, fileTranslationStatus) {
      state.fileTranslationStatus = fileTranslationStatus;
    },

    setFileDownload(state, fileDownload) {
      state.fileDownload = fileDownload;
    },

    setTraceQuery(state, traceQuery) {
      state.traceQuery = traceQuery;
    },

    setTraceMatch(state, traceMatch) {
      state.traceMatch = traceMatch;
    },

    setTokenizedSentence(state, tokenizedSentence) {
      state.tokenizedSentence = tokenizedSentence;
    },

    setTokenizedNewText(state, tokenizedNewText) {
      state.tokenizedNewText = tokenizedNewText;
    }
  },
  actions: {
    tokenizeSentence({ commit }, input) {
      axios
        .post(config.apiUrl + "backend/tokenize", {
          text: input,
        })
        .then((data) => {
          let tokenized = data.data;
          commit("setTokenizedSentence", tokenized);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tokenizeNewText({ commit }, newText) {
      axios
        .post(config.apiUrl + "backend/tokenize_new_text", {
          text: newText,
        })
        .then((data) => {
          let tokenized = data.data;
          console.log("tokenized", tokenized)
          commit("setTokenizedNewText", tokenized);
        })
        .catch((error) => {
          console.log(error);
        })
    },
    translateInput({ commit }, input) {
      commit("setInput", input);
      axios
        .post(config.apiUrl + "backend/translate", {
          text: input,
          bullinger: false,
        })
        .then((data) => {
          let original = data.data[0];
          let translation = data.data[1];
          
          commit("setTranslation", translation);
          commit("setOriginal", original);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    translateBullingerInput({ commit }, data) {
      commit("setInput", data.input);
      axios
        .post(config.apiUrl + "backend/translate_bullinger", {
          text: data.input,
          original: data.original,
        })
        .then((data) => {
          let original = data.data[0];
          let translation = data.data[1];
          
          commit("setTranslation", translation);
          commit("setOriginal", original);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    clearTranslation({ commit }) {
      commit("setTranslation", "");
    },

    translateTXTFile({ commit }, query) {
      commit("setFile", query.file);
      commit("setFileTranslation", null);
      axios
        .post(config.apiUrl + "backend/translate_file", {
          text: query.file,
          name: query.name,
        })
        .then((response) => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileUrl;
          let new_filename = query.name.slice(0, -4) + "_translated.txt";
          fileLink.setAttribute("download", new_filename);

          document.body.appendChild(fileLink);

          commit("setFileDownload", fileLink);
          commit("setFileTranslationStatus", 200);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetFileTranslationStatus({ commit }) {
      commit("setFileTranslationStatus", null);
    },

    downloadFile({ getters }) {
      let download = getters.getFileDownload;
      download.click();
    },

    traceText({ commit }, query) {
      commit("setTraceQuery", query.text);
      axios
        .post(config.apiUrl + "backend/traceback_text", {
          text: query.text,
        })
        .then((data) => {
          let result = data.data;
          for (let i = 0; i < result.length; i++) {
            axios
              .post(config.apiUrl + "backend/normalize_traceback", {
                text: result[i],
              })
              .then((response) => {
                let match = response.data;

                if (query.original === match) {
                  commit("setTraceMatch", result[i]);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    clearTrace({ commit }) {
      commit("setTraceQuery", null);
      commit("setTraceMatch", null);
    },
  },
  getters: {
    getFileDownload(state) {
      return state.fileDownload;
    },
  },
  modules: {},
});
