<template>
  <div class="text-style">
    <b-row>
      <b-col cols="auto" class="text-button">
        <b-button
          variant="outline-primary"
          size="sm"
          :pressed="options.text"
          v-on:click="selectText"
        >
          <b-icon icon="textarea-t"></b-icon> Text
        </b-button>
      </b-col>
      <b-col cols="auto" class="document-button">
        <b-button
          variant="outline-primary"
          size="sm"
          :pressed="options.document"
          v-on:click="selectDocument"
        >
          <b-icon icon="file-text-fill"></b-icon> Dokumente
        </b-button>
      </b-col>
    </b-row>
    <div class="pt-2 textarea-wrapper" v-if="options.text">
      <div class="source-textarea container" id="source">
        <b-row>
          <b-col class="language-latin" cols="auto"> Latein </b-col>
          <b-col class="source-document-mid" cols="auto"
            ><span
              v-if="
                sourceDocument !== null &&
                bullingerText !== '' &&
                fromBullinger === true
              "
              >&nbsp;-&nbsp;{{ sourceDocument }}</span
            ></b-col
          >
          <b-col class="source-document">
            <span
              v-if="fromBullinger === true && bullingerEditedStatus === true"
            >
              &nbsp;[edited]
            </span>
          </b-col>
          <b-col
            class="source-document trash-icon"
            style="align"
            v-if="fromBullinger === true"
          >
            <span v-on:click="clearSourceText">
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </span>
          </b-col>
        </b-row>
        <b-row class="source">
          <b-col>
            <div
              class="inputtext defaultinputtext inputTextarea"
              id="inputText"
              contenteditable="true"
              spellcheck="false"
              ref="textinput"
              @input="handleInput"

            ></div>
          </b-col>
        </b-row>
        <b-row class="text-count" v-if="fromBullinger === false">
          <b-col
            >{{ textCount.toLocaleString("en-US") }} /
            {{ textLimit.toLocaleString("en-US") }}</b-col
          >
        </b-row>
      </div>
      <div class="target-textarea container" id="target">
        <b-row>
          <b-col class="language"> Deutsch </b-col>
        </b-row>
        <b-row class="target" v-if="!loading">
          <b-col>
            <div
              data-text="Translation"
              id="div-translation"
              class="div-translation"
              contenteditable="false"
            >
              <div
                v-for="(list, idx1) in translationText"
                :key="idx1"
              >
                <span v-for="(text, idx2) in list" :key="idx2">
                  <span
                    v-if="notLatinTexts.includes(text)"
                    class="translation-highlight german-text"
                    @mouseover="
                      mouseOver(
                        original[idx1][idx2],
                        idx1,
                        idx2,
                        list.length,
                        original
                      )
                    "
                    @mouseout="mouseLeave(original[idx1][idx2], idx1, idx2)"
                    >{{ text }}</span
                  >
                  <span
                    v-else
                    class="translation-highlight"
                    @mouseover="
                      mouseOver(
                        original[idx1][idx2],
                        idx1,
                        idx2,
                        list.length,
                        original
                      )
                    "
                    @mouseout="mouseLeave(original[idx1][idx2], idx1, idx2)"
                    >{{ text }}</span
                  >
                  <span v-if="list.length - 1 != idx2">&nbsp;</span>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-else class="spinner-container">
          <b-col align-self="center">
            <RiseLoader :color="color" />
          </b-col>
        </b-row>
      </div>
    </div>
    <b-row class="pt-2" v-else-if="options.document">
      <b-col class="source-upload">
        <b-row>
          <b-col class="language">Latin</b-col>
        </b-row>
        <b-row
          @drop="dropFile"
          @dragover.prevent
          @drop.prevent
          @dragenter="dragEnter"
          @dragleave="dragLeave"
          class="div-upload text-center"
          align-v="center"
          align-h="center"
          v-if="filename === null"
        >
          <b-col
            v-if="!dragEnterStatus && !fileLoading"
            contenteditable="false"
          >
            <b-row>
              <b-col contenteditable="false">
                <b-icon
                  icon="file-text"
                  style="width: auto; height: 70px"
                ></b-icon>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="upload-text-1" contenteditable="false">
                Drag and drop your .txt file here
              </b-col>
            </b-row>
            <b-row>
              <b-col class="upload-text-2" contenteditable="false"> or </b-col>
            </b-row>
            <b-row>
              <b-col contenteditable="false">
                <div class="input-file">
                  <input
                    type="file"
                    id="upload-file"
                    accept=".txt"
                    @change="fileSelected"
                    hidden
                  />
                  <b-button variant="primary" @click="selectFile()"
                    >Select from your computer</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else>
            <b-row>
              <b-col class="drag-text-1" contenteditable="false">
                Drop to upload.
              </b-col>
            </b-row>
            <b-row>
              <b-col class="drag-text-2" contenteditable="false"
                >Only single .txt file can be translated.</b-col
              >
            </b-row>
          </b-col>
        </b-row>
        <b-row
          class="div-upload text-center"
          align-v="center"
          align-h="center"
          v-else
        >
          <b-col cols="8" v-if="!fileLoading" contenteditable="false">
            <b-row class="file-selected" contenteditable="false">
              <b-col cols="2" align-self="center" contenteditable="false">
                <b-icon
                  icon="file-text"
                  style="width: auto; height: 40px"
                  variant="secondary"
                ></b-icon>
              </b-col>
              <b-col
                style="text-align: left"
                align-self="center"
                contenteditable="false"
              >
                {{ filename }}
              </b-col>
              <b-col cols="2" align-self="center" contenteditable="false">
                <b-button variant="light" v-on:click="removeFile" size="sm">
                  <b-icon icon="x"></b-icon>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col contenteditable="false">
                <b-button variant="primary" v-on:click="readFile"
                  >Translate</b-button
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else>
            <b-row>
              <b-col align-self="center">
                <SyncLoader :color="color" />
              </b-col>
            </b-row>
            <b-row>
              <span class="inprogress-file"> Translating file... </span>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="target-upload">
        <b-row>
          <b-col class="language">German</b-col>
        </b-row>
        <b-row
          align-v="center"
          align-h="center"
          class="div-translate text-center"
        >
          <b-col v-if="fileTranslationStatus === 200">
            <b-row>
              <b-col align-self="center">
                <b-button
                  squared
                  variant="primary"
                  size="lg"
                  v-on:click="downloadFile"
                >
                  <b-icon icon="download" aria-hidden="true"></b-icon> Download
                  translation
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Split from "split.js";
import { mapState } from "vuex";
import _ from "lodash";
import RiseLoader from "vue-spinner/src/RiseLoader.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import dictionary from "../assets/dictionary.json";
import greetings from "../assets/greetings.json";

export default {
  name: "Translator",
  components: {
    RiseLoader,
    SyncLoader,
  },

  data() {
    return {
      loading: false,
      input: "",
      translate: "",
      color: "rgba(103, 128, 159, 1)",
      lines: [],
      options: {
        text: null,
        document: null,
      },
      file: null,
      filename: null,
      file_input: "",
      dragEnterStatus: false,
      dragLeaveStatus: true,
      fileLoading: false,
      translationText: "",
      originalText: "",
      fromBullinger: false,
      bullingerText: "",
      bullingerOriginal: "",
      bullingerForTranslation: null,
      bullingerTranslation: null,
      bullingerEditedStatus: false,
      containerIndex: null,
      textLimit: 5000,
      textCount: 0,
      notLatinTexts: [],
      germanTexts: [],
      sourceDocument: null,
      caretPosition: null,
      MEDPercentage: 0.0,
      wordDictionary: dictionary,
      notLatinIndexes: [],
      previousText: [],
      previousTextLatin: null,
      newBullingerOriginal: null,
      nonLatinTexts: [],
      forTokenization: [],
      newSentenceTokenization: [],
      tokenizedNewSentence: null,
      processedParagraph: "",
    };
  },

  mounted() {
    this.options.text = true;
    this.options.document = false;
    this.$nextTick(() => {
      Split(['#source', '#target'], {
          sizes: [50, 50],
      })
      
      this.$store.dispatch("clearTranslation");
      this.$refs.textinput.focus();

      // Listen for messages with content to be translated
      window.addEventListener("message", (e) => {
        if (e.data.type === "translation-request") {
          document.getElementById("inputText").innerHTML = "";
          this.$store.dispatch("clearTranslation");
          this.fromBullinger = true;
          this.$router.replace({ path: "/", query: { source: e.data.source } });
          this.sourceDocument = e.data.source;
          let translationRequest = e.data.data;

          // check greetings and seprate them from the paragraph
          let updatedTranslationRequest = [];
          for (let i = 0; i < translationRequest.length; i++) {
            // check if paragraph has more than 1 sentence
            if (translationRequest[i].length > 1) {
              // create new array for greetings
              if (
                greetings["greetings"].includes(
                  translationRequest[i][0]["sentence"]
                )
              ) {
                updatedTranslationRequest.push([translationRequest[i][0]]);
                translationRequest[i].shift();
                updatedTranslationRequest.push(translationRequest[i]);
              }
              // copy original array
              else {
                updatedTranslationRequest.push(translationRequest[i]);
              }
            }
            // copy original array
            else {
              updatedTranslationRequest.push(translationRequest[i]);
            }
          }
          this.bullingerOriginal = updatedTranslationRequest;
          translationRequest = updatedTranslationRequest;

          let latin = "la";
          let forTranslation = [];
          for (let i = 0; i < translationRequest.length; i++) {
            let newDiv = document.createElement("div");

            let newParagraph = [];
            for (let j = 0; j < translationRequest[i].length; j++) {
              this.previousText.push(translationRequest[i][j]["sentence"]);
              if (translationRequest[i][j]["lang"] == latin) {
                forTranslation.push(translationRequest[i][j]["sentence"]);
              } else {
                this.nonLatinTexts.push(translationRequest[i][j]["sentence"]);
              }
              newParagraph.push(translationRequest[i][j]["sentence"]);
            }

            for (let q = 0; q < newParagraph.length; q++) {
              let newSpan = document.createElement("span");
              newSpan.innerText = newParagraph[q];
              newDiv.appendChild(newSpan);
              if (q + 1 !== newParagraph.length) {
                newDiv.innerHTML += " ";
              }
            }

            document.getElementById("inputText").appendChild(newDiv);
            this.bullingerText = document.getElementById("inputText").innerText;
          }

          this.bullingerForTranslation = forTranslation;
          this.bullingerOriginal = translationRequest;
          this.input = this.bullingerForTranslation;
          this.previousTextLatin = this.bullingerForTranslation;
        } else {
          this.bullingerText = "";
          this.fromBullinger = false;
        }
      });

      if (window && window.opener) {
        // Send "ready" signal to the opener
        setTimeout(function () {
          window.opener.postMessage(
            {
              type: "translation-app-ready",
            },
            "*"
          );
        }, 1000);
      }
      this.$store.dispatch("clearTranslation");
      this.$refs.textinput.focus();

      if (this.$route.query.latin !== undefined) {
        document.getElementById("inputText").innerText =
          this.$route.query.latin;

        this.input = document.getElementById("inputText").innerText;
      }
    });
  },


  watch: {
    translation() {
      this.loading = false;
    },

    input() {
      if (this.fromBullinger === false) {
        this.lines = this.input.split("\n");
        if (this.translate !== this.input.trim()) {
          if (this.textCount <= this.textLimit) {
            this.proceed();
          }
        }
      } else {
        if (this.bullingerEditedStatus === true) {
          this.wrapTextToHTML();
        }
        else {
          this.proceed();
        }
      }
    },

    translate() {
      this.getTranslation();
    },

    file_input() {
      this.translateFile();
    },

    fileTranslation() {
      this.fileLoading = false;
    },

    traceMatch() {
      this.highlightSpecialCase();
    },

    tokenizedSentence() {
      this.wordsInDictionary(this.tokenizedSentence);
    },

    tokenizedNewText() {
      let editedBullinger = document.getElementById("inputText");
      let divNodes = editedBullinger.children.length;

      // Structure split sentences into 'span'
      for (let i = 0; i < this.newSentenceTokenization.length; i++) {
        for (let j = 0; j < this.newSentenceTokenization[i].length; j++) {
          for (let x = 0; x < divNodes; x++) {
            let paragraphNodes = editedBullinger.childNodes[x];
            for (let y = 0; y < paragraphNodes.childNodes.length; y++) {
              if (paragraphNodes.childNodes[y].textContent.trim() !== "") {
                if (paragraphNodes.childNodes[y].textContent === this.newSentenceTokenization[i][j]) {
                  let reversedNewText = [...this.tokenizedNewText[i]].reverse();
                  for (let r = 0; r < reversedNewText.length; r++) {
                    let newSpan = document.createElement("span");
                    newSpan.innerText = reversedNewText[r];
                    paragraphNodes.childNodes[y].after(newSpan);
                    newSpan.after(" ")
                  }
                  paragraphNodes.childNodes[y].remove();
                }
              }
            }
          }
        }
      }

      // get all split sentences and replace content of bullingerOriginal
      let newStructure = [];
      for (let i = 0; i < divNodes; i++) {
        let paragraphNodes = editedBullinger.childNodes[i];
        let paragraph = [];

        for (let j = 0; j < paragraphNodes.childNodes.length; j++) {
          if (paragraphNodes.childNodes[j].textContent.trim() !== "") {
            let sentenceMatch = false;
            for (let x = 0; x < this.bullingerOriginal.length; x++) {
              for (let y = 0; y < this.bullingerOriginal[x].length; y++) {
                if (paragraphNodes.childNodes[j].textContent === this.bullingerOriginal[x][y]["sentence"]) {
                  paragraph.push(this.bullingerOriginal[x][y]);
                  sentenceMatch = true;
                  break;
                }
              }
              if (sentenceMatch === true) {
                break;
              }
            }
            if (sentenceMatch === false) {
              paragraph.push({lang: "la", sentence: paragraphNodes.childNodes[j].textContent});

            }
          }
        }
        newStructure.push(paragraph);
      }
      this.bullingerOriginal = newStructure;
      this.proceed();
    },
  },

  computed: {
    ...mapState({
      tokenizedSentence(state) {
        return state.tokenizedSentence;
      },

      tokenizedNewText(state) {
        // if (this.bullingerEditedStatus === true && state.tokenizedNewText !== null) {
        return state.tokenizedNewText;
        // }
      },

      translation(state) {
        // prepare translation when source text is from bullinger
        if (this.fromBullinger === true) {
          let language = "la";
          this.bullingerTranslation = state.translation[0];

          let fullTranslation = [];
          this.notLatinTexts = [];
          if (this.bullingerTranslation !== undefined && this.bullingerTranslation !== []) {
            if (this.bullingerTranslation.length > 0) {
              for (let i = 0; i < this.bullingerOriginal.length; i++) {
                let translation = [];
                for (let j = 0; j < this.bullingerOriginal[i].length; j++) {
                  if (this.bullingerOriginal[i][j]["lang"] === language) {
                    translation.push(this.bullingerTranslation[0]);
                    this.bullingerTranslation.shift();
                  } else {
                    translation.push(this.bullingerOriginal[i][j]["sentence"]);
                    this.notLatinTexts.push(
                      this.bullingerOriginal[i][j]["sentence"]
                    );

                    if (this.bullingerOriginal[i][j]["lang"] === "de") {
                      this.germanTexts.push(
                        this.bullingerOriginal[i][j]["sentence"]
                      );
                    }
                  }
                }
                fullTranslation.push(translation);
                this.translationText = fullTranslation;
              }
            }
          }
        }
        // no translation preparation needed for source text if not coming from bullinger
        else {
          this.translationText = state.translation;
        }

        return this.translationText;
      },

      original(state) {
        if (this.fromBullinger === true) {
          let originalBullingerList = [];
          for (let i = 0; i < this.bullingerOriginal.length; i++) {
            let paragraph = [];
            for (let j = 0; j < this.bullingerOriginal[i].length; j++) {
              paragraph.push(this.bullingerOriginal[i][j]["sentence"]);
            }
            originalBullingerList.push(paragraph);
          }
          return originalBullingerList;
        } else {
          let originalText = state.original;
          return originalText;
        }
      },

      fileTranslation(state) {
        return state.fileTranslation;
      },

      fileTranslationStatus(state) {
        this.fileLoading = false;
        return state.fileTranslationStatus;
      },

      traceMatch(state) {
        return state.traceMatch;
      },
    }),
  },

  methods: {
    // clear text area of source text and target
    clearSourceText() {
      let f = function (e) {
        console.log(e);
        window.removeEventListener("message", f, false);
      };
      window.removeEventListener("message", f);
      document.getElementById("inputText").innerText = "";
      this.$router.replace({ path: "/" });
      this.$store.dispatch("clearTranslation");
      this.fromBullinger = false;
      this.bullingerEditedStatus = false;
      this.notLatinTexts = [];
    },

    // process input text from user in the text area
    handleInput() {
      if (this.fromBullinger === true) {
        this.bullingerEditedStatus = true;
        if (this.processedParagraph !== document.getElementById("inputText").innerText) {
          this.input = "";
          this.input = document.getElementById("inputText");
        }
      } else {
        let text = document.getElementById("inputText").innerText;
        this.textCount = text.trimEnd().length;

        if (this.textCount > this.textLimit) {
          this.input = document
            .getElementById("inputText")
            .innerText.substring(0, this.textLimit);
          document.getElementById("inputText").innerText = document
            .getElementById("inputText")
            .innerText.substring(0, this.textLimit);
        } else {
          this.input = document.getElementById("inputText").innerText;
          this.$router.replace({ path: "/", query: { latin: this.input } });
        }
      }
    },

    selectText() {
      this.options.text = true;
      this.options.document = false;
      this.$store.dispatch("clearTranslation");
      this.file = null;
      this.filename = null;
      this.dragEnterStatus = false;
      this.dragLeaveStatus = true;
      this.fileLoading = false;
      this.$store.dispatch("resetFileTranslationStatus");
      this.file_input = "";
    },

    selectDocument() {
      this.options.text = false;
      this.options.document = true;
      document.getElementById("inputText").innerText = "";
      this.handleInput();
      this.file = null;
      this.filename = null;
      this.dragEnterStatus = false;
      this.dragLeaveStatus = true;
      this.fileLoading = false;
      this.$store.dispatch("resetFileTranslationStatus");
      this.file_input = "";
    },

    // remove format of texts pasted in source text area
    onPaste(e) {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    },

    // wrap new sentences inside span element when text from bullinger is edited
    wrapTextToHTML: _.debounce(function () {
      let editedBullinger = document.getElementById("inputText");
      let divNodes = editedBullinger.children.length;

      // get paragraph HTML and wrap new sentence in 'span' tag
      for (let i = 0; i < divNodes; i++) {
        let paragraphNodes = editedBullinger.childNodes[i];
        for (let j = 0; j < paragraphNodes.childNodes.length; j++) {
          if (paragraphNodes.childNodes[j].nodeType === 3 && paragraphNodes.childNodes[j].textContent.trim() !== "") {
            let newSpan = document.createElement("span");
            paragraphNodes.childNodes[j].after(newSpan);
            newSpan.appendChild(paragraphNodes.childNodes[j])
            newSpan.textContent = newSpan.textContent.trim();
            newSpan.before(" ");
            newSpan.after(" ");
          }
        }
      }

      // get all sentences in text area and check if there are new sentences
      // all new sentences will be segmented using CLTK splitter
      this.newSentenceTokenization = [];
      for (let i = 0; i < divNodes; i++) {
        let paragraphNodes = editedBullinger.childNodes[i];

        for (let j = 0; j < paragraphNodes.childNodes.length; j++) {
          if (paragraphNodes.childNodes[j].textContent.trim() !== "") {
            let sentenceMatch = false;

            for (let x = 0; x < this.bullingerOriginal.length; x++) {
              for (let y = 0; y < this.bullingerOriginal[x].length; y++) {
                if (paragraphNodes.childNodes[j].textContent === this.bullingerOriginal[x][y]["sentence"]) {
                  sentenceMatch = true;
                  break;
                }
              }
              if (sentenceMatch === true) {
                break;
              }
            }
            if (sentenceMatch === false) {
              this.newSentenceTokenization.push([paragraphNodes.childNodes[j].textContent]);
            }
          }
        }
      }

      // check if there are sentenes that needs to be tokenized before translating the whole text
      if (this.newSentenceTokenization.length > 0) {
        this.$store.dispatch("tokenizeNewText", this.newSentenceTokenization);
      } else {
        this.reprocessText();
      }
    }, 1000),

    // get text from the source text area for translation
    proceed: _.debounce(function () {
      let inputText = document
        .getElementById("inputText")
        .innerText.split("\n");

      // CARET SOLUTION 1 START
      var el = document.getElementById("inputText");
      let position = 0;
      const isSupported = typeof window.getSelection !== "undefined";
      if (isSupported) {
        const selection = window.getSelection();
        // Check if there is a selection (i.e. cursor in place)
        if (selection.rangeCount !== 0) {
          // Store the original range
          const range = window.getSelection().getRangeAt(0);
          // Clone the range
          const preCaretRange = range.cloneRange();
          // Select all textual contents from the contenteditable element
          preCaretRange.selectNodeContents(el);
          // And set the range end to the original clicked position
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          // Return the text length from contenteditable start to the range end
          position = preCaretRange.toString().length;
        }
      }
      // CARET SOLUTION 1 START
      if (this.bullingerForTranslation === null) {
        let newInput = ``;
        for (let i = 0; i < inputText.length; i++) {
          newInput += `<div>${inputText[i]}</div>`;
        }
        document.getElementById("inputText").innerHTML = newInput;
      }

      // CARET SOLUTION 1 START
      this.caretPosition = position;
      let newPosition = this.setCaretPosition(el);
      console.info("position", newPosition);
      // CARET SOLUTION 1 END

      // tokenize bullinger input to get definition of words from dictionary
      if (this.fromBullinger === true) {
        let fullBullingerText = [];
        for (let i = 0; i < this.bullingerOriginal.length; i++) {
          let paragraph = [];
          for (let j = 0; j < this.bullingerOriginal[i].length; j++) {
            paragraph.push(this.bullingerOriginal[i][j]["sentence"]);
          }

          fullBullingerText.push(paragraph.join(" "));
        }

        let concatFullBullingerText = fullBullingerText.join("\n");
        this.$store.dispatch("tokenizeSentence", concatFullBullingerText);
      }

      if (this.fromBullinger === true) {
        let newInput = []
        for (let i = 0; i < this.bullingerOriginal.length; i++) {
          for (let j = 0; j < this.bullingerOriginal[i].length; j++) {
            if (this.bullingerOriginal[i][j]["lang"] === "la") {
              newInput.push(this.bullingerOriginal[i][j]["sentence"]);
            }
          }
        }
        this.translate = newInput;
      } else {
        this.translate = this.input;
      }

    }, 1000),

    reprocessText() {
      let editedBullinger = document.getElementById("inputText");
      let divNodes = editedBullinger.children.length;

      // get all split sentences and replace content of bullingerOriginal
      let newStructure = [];
      for (let i = 0; i < divNodes; i++) {
        let paragraphNodes = editedBullinger.childNodes[i];
        let paragraph = [];

        for (let j = 0; j < paragraphNodes.childNodes.length; j++) {
          if (paragraphNodes.childNodes[j].textContent.trim() !== "") {
            let sentenceMatch = false;
            for (let x = 0; x < this.bullingerOriginal.length; x++) {
              for (let y = 0; y < this.bullingerOriginal[x].length; y++) {
                if (paragraphNodes.childNodes[j].textContent === this.bullingerOriginal[x][y]["sentence"]) {
                  paragraph.push(this.bullingerOriginal[x][y]);
                  sentenceMatch = true;
                  break;
                }
              }
              if (sentenceMatch === true) {
                break;
              }
            }
            if (sentenceMatch === false) {
              paragraph.push({lang: "la", sentence: paragraphNodes.childNodes[j].textContent});

            }
          }
        }
        newStructure.push(paragraph);
      }
      this.bullingerOriginal = newStructure;
      this.proceed();
    },

    wordsInDictionary(tokenizedSentence) {
      let uniqueWords = [...new Set(tokenizedSentence)];
      if (this.fromBullinger === true) {
        this.highlightGermanSentences();
        if (this.notLatinIndexes.length > 0) {
          let divContainer = document.querySelector(
            `#inputText div:nth-child(${this.notLatinIndexes[0] + 1})`
          );
          for (let i = 0; i < divContainer.children.length; i++) {
            for (let j = 0; j < uniqueWords.length; j++) {
              if (uniqueWords[j] in this.wordDictionary) {
                let re = new RegExp(`(${uniqueWords[j]})`, "g");
                divContainer.children[i].innerHTML = divContainer.children[
                  i
                ].innerHTML.replace(
                  re,
                  `<span class="word-meaning" v-b-tooltip.focus title="${
                    this.wordDictionary[uniqueWords[j]]
                  }">$&</span>`
                );
              }
            }
          }
          this.notLatinIndexes = [];
        } else {
          // dictionary lookup - START
          for (let i = 0; i < uniqueWords.length; i++) {
            if (uniqueWords[i] in this.wordDictionary) {
              let re = new RegExp(`(${uniqueWords[i]})`, "g");
              let input = document.getElementById("inputText").innerHTML;
              document.getElementById("inputText").innerHTML = input.replace(
                re,
                `<span class="word-meaning" v-b-tooltip.focus title="${
                  this.wordDictionary[uniqueWords[i]]
                }">$&</span>`
              );
            }
          }
          // dictionary lookup - END
        }
      }
    },

    // update this.bullingerOriginal when billinger text is edited
    createNewBullingerOriginal() {
      let editedBullinger = document.getElementById("inputText");
      let divNodes = editedBullinger.children.length;

      // move new text to this.bullingerOriginal
      for (let i = 0; i < divNodes; i++) {
        let paragraphNodes = editedBullinger.childNodes[i];
        for (let j = 0; j < paragraphNodes.children.length; j++) {
          if (paragraphNodes.childNodes[j].textContent !== " ") {
            let text = paragraphNodes.childNodes[j].textContent;
            this.bullingerOriginal[i][j]["sentence"] = text;
          }
        }
      }
    },

    // sentence alignment highlighting
    highlightGermanSentences() {
      if (this.notLatinIndexes.length > 0) {
        let divContainer = document.querySelector(
          `#inputText div:nth-child(${this.notLatinIndexes[0] + 1})`
        );
        for (let i = 0; i < divContainer.children.length; i++) {
          if (this.notLatinTexts.includes(divContainer.children[i].innerText)) {
            divContainer.children[
              i
            ].innerHTML = `<span class="german-text">${divContainer.children[i].innerText}</span>`;
          }
        }
      } else {
        for (let i = 0; i < this.notLatinTexts.length; i++) {
          let notLatin = this.notLatinTexts[i].replace(
            /[-/\\^$*+?.()|[\]{}]/g,
            "\\$&"
          );
          let re = new RegExp(`(${notLatin})`, "g");
          let input = document.getElementById("inputText").innerHTML;
          document.getElementById("inputText").innerHTML = input.replace(
            re,
            `<span class="german-text">$&</span>`
          );
        }
      }
    },

    // maintain cursor position when user edits the text
    setCaretPosition(el) {
      // Loop through all child nodes
      for (let i = 0; i < el.childNodes.length; i++) {
        let node = el.childNodes[i];

        if (node.nodeType == Node.TEXT_NODE) {
          // we have a text node
          if (node.length >= this.caretPosition) {
            // finally add our range
            var range = document.createRange();
            var sel = window.getSelection();
            range.setStart(node, this.caretPosition);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            return -1; // we are done
          } else {
            this.caretPosition = this.caretPosition - node.length;
          }
        } else {
          let posIndicator = this.setCaretPosition(node);
          if (posIndicator == -1) {
            return -1; // no need to finish the for loop
            // break;
          }
        }
      }
    },

    // send the text to the backend for translation
    getTranslation() {
      if (this.translate.length !== 0) {
        this.loading = true;
        // Is this needed in the future?
        // this.$store.dispatch("clearTranslation");


        if (this.fromBullinger === true) {
          this.$store.dispatch("translateBullingerInput", {
            input: this.translate,
            original: this.bullingerOriginal,
          });
        } else {
          this.$store.dispatch("translateInput", this.translate);
        }
      } else {
        this.$store.dispatch("clearTranslation");
      }
    },

    selectFile() {
      if (this.options.document === true) {
        document.getElementById("upload-file").click();
      }
    },

    fileSelected(e) {
      this.file = e.target.files[0];
      this.filename = e.target.files[0].name;
    },

    removeFile() {
      this.file = null;
      this.filename = null;
      this.dragEnterStatus = false;
      this.dragLeaveStatus = true;
      this.$store.dispatch("resetFileTranslationStatus");
    },

    dropFile(e) {
      if (e.dataTransfer.files.length > 1) {
        this.$notify.danger("Multiple files not allowed!");
      } else {
        this.file = e.dataTransfer.files[0];
        this.filename = e.dataTransfer.files[0].name;
      }
    },

    dragEnter(e) {
      e.preventDefault();
      this.dragEnterStatus = true;
      this.dragLeaveStatus = false;
    },

    dragLeave(e) {
      e.preventDefault();
      this.dragEnterStatus = false;
      this.dragLeaveStatus = true;
    },

    readFile() {
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onloadend = () => {
        if (reader.result !== this.file_input) {
          this.file_input = reader.result;
        } else {
          this.$notify.danger("File content already translated!");
        }
      };
    },

    translateFile() {
      this.$store.dispatch("resetFileTranslationStatus");
      this.fileLoading = true;
      this.$store.dispatch("translateTXTFile", {
        file: this.file_input,
        name: this.filename,
      });
    },

    downloadFile() {
      this.$store.dispatch("downloadFile", this.filename);
    },

    // sentence alignment highlighting
    mouseOver(original, idx, textIndex, listLength, originalList) {
      let count = document.getElementById("inputText").children.length;
      // when source text has only one paragraph
      if (count === 0) {
        let input = document.getElementById("inputText").innerText;

        if (listLength === 1) {
          document.getElementById("inputText").innerHTML = input.replace(
            input,
            `<span class="original-highlight">$&</span>`
          );
        } else {
          const regex = new RegExp(original, "g");
          let result = input.match(regex);

          if (result) {
            if (result.length > 1) {
              let n = 0;

              for (let i = 0; i < originalList[0].length; i++) {
                if (originalList[0][i] === result[0]) {
                  n += 1;
                }
                if (i === textIndex) {
                  break;
                }
              }

              let search = result[0];
              document.getElementById("inputText").innerHTML = input.replace(
                RegExp(`^(?:.*?${result[0]}){` + n + `}`),
                function (x) {
                  return x.replace(
                    RegExp(search + "$"),
                    `<span class="original-highlight">${result[0]}</span>`
                  );
                }
              );
            } else {
              document.getElementById("inputText").innerHTML = input.replace(
                result,
                `<span class="original-highlight">$&</span>`
              );
            }
          } else {
            this.$store.dispatch("traceText", {
              text: document.getElementById("inputText").innerText,
              original: original,
            });
          }
        }
      }
      // when source text has more than one paragraph
      else {
        let divContainer = document.querySelector(
          `#inputText div:nth-child(${idx + 1})`
        );
        const regex = new RegExp(
          original.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"),
          "g"
        );
        let result = divContainer.innerText.match(regex);
        if (result) {
          if (result.length > 1) {
            let n = 0;

            for (let i = 0; i < originalList[0].length; i++) {
              if (originalList[0][i] === result[0]) {
                n += 1;
              }
              if (i === textIndex) {
                break;
              }
            }

            let search = result[0];
            if (this.fromBullinger === true) {
              for (let i = 0; i < divContainer.childNodes.length; i++) {
                if (divContainer.childNodes[i].textContent.trim() === "") {
                  textIndex += 1;
                }
                if (i === textIndex) {
                  break;
                }
              }
              divContainer.childNodes[textIndex].innerHTML = divContainer.childNodes[textIndex].innerText.replace(
                divContainer.childNodes[textIndex].textContent, `<span class="original-highlight">${result[0]}</span>`
              );
            } else {
              divContainer.innerHTML = divContainer.innerText.replace(
                RegExp(`^(?:.*?${result[0]}){` + n + `}`),
                function (x) {
                  return x.replace(
                    RegExp(search + "$"),
                    `<span class="original-highlight">${result[0]}</span>`
                  );
                }
              );
            }
          } else {
            if (this.fromBullinger === true) {
              divContainer.innerHTML = "";
              let paragraph = this.bullingerOriginal[idx];
              for (let i = 0; i < paragraph.length; i++) {
                if (result[0] === paragraph[i]["sentence"]) {
                  divContainer.innerHTML += `<span class="original-highlight">${paragraph[i]["sentence"]}</span>`;
                } else {
                  if (paragraph[i]["lang"] === "la") {
                    divContainer.innerHTML += `<span>${paragraph[i]["sentence"]}</span>`;
                  } else {
                    divContainer.innerHTML += `<span><span class="german-text">${paragraph[i]["sentence"]}</span></span>`;
                  }
                }
                if (i + 1 !== paragraph.length) {
                  divContainer.innerHTML += " ";
                }
              }
            } else {
              divContainer.innerHTML = divContainer.innerText.replace(
                result,
                `<span class="original-highlight">$&</span>`
              );
            }
          }
        } else {
          this.$store.dispatch("traceText", {
            text: document.getElementById("inputText").innerText,
            original: original,
          });
        }
      }
    },

    // remove sentence alignment highlighting
    mouseLeave(original, idx1, idx2) {
      this.$store.dispatch("clearTrace");
      let count = document.getElementById("inputText").children.length;
      if (count === 1) {
        let input = document.getElementById("inputText").innerText;
        document.getElementById("inputText").innerHTML = input.replace(
          document.getElementById("inputText").innerHTML,
          input
        );
      } else {
        let input = document.getElementById("inputText").innerHTML;

        let divContainer = document.querySelector(
          `#inputText div:nth-child(${idx1 + 1})`
        );

        let divText = divContainer.innerText;
        if (this.fromBullinger === true) {
          divContainer.innerHTML = "";
          let paragraph = this.bullingerOriginal[idx1];
          for (let i = 0; i < paragraph.length; i++) {
            if (paragraph[i]["lang"] !== "la") {
              divContainer.innerHTML += `<span>${paragraph[i]["sentence"]}</span>`;
            } else {
              divContainer.innerHTML += `<span>${paragraph[i]["sentence"]}</span>`;
            }
            if (i + 1 !== paragraph.length) {
              divContainer.innerHTML += " ";
            }
          }
          this.notLatinIndexes.push(idx1, idx2);
        } else {
          document.getElementById("inputText").innerHTML = input.replace(
            divContainer.innerHTML,
            divText
          );
        }
      }
      this.wordsInDictionary(this.tokenizedSentence);
    },

    highlightSpecialCase() {
      let count = document.getElementById("inputText").children.length;

      if (count === 0) {
        let input = document.getElementById("inputText").innerHTML;
        document.getElementById("inputText").innerHTML = input.replace(
          this.traceMatch,
          `<span class="original-highlight">$&</span>`
        );
      } else {
        let divContainer = document.querySelector(
          `#inputText div:nth-child(${this.containerIndex})`
        );
        divContainer.innerHTML = divContainer.innerText.replace(
          this.traceMatch,
          `<span class="original-highlight">$&</span>`
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,700;1,200;1,400;1,700&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,700;1,200;1,400;1,700&display=swap");

.text-style {
  font-family: "Source Serif Pro", serif;
}

.text-button,
.document-button {
  padding-left: 0px !important;
  margin: 5px !important;
}

textarea {
  border: 0;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 20px;
  vertical-align: top;
  font-size: 22px !important;
  outline: 0;
  background: transparent;
  max-height: calc(100vh - 20px);
}

[contenteditable] {
  outline: 0px solid transparent;
}

.textarea-wrapper {
  display: flex;
  margin:0 calc(-0.5 * var(--bs-gutter-x))
}

.source-textarea {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(200, 200, 200, 1);
  margin: 5px !important;
  overflow: auto;
}

.text-count {
  padding-top: 4px;
  padding-bottom: 3px;
  text-align: end;
  font-size: 14px;
}

.target-textarea {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(200, 200, 200, 1);
  margin: 5px !important;
  overflow: auto;
}

.resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  z-index: 1;
}

.source-upload {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(200, 200, 200, 1);
  margin: 5px !important;
}

.target-upload {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(200, 200, 200, 1);
  margin: 5px !important;
}

.inputTextarea {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.inputtext {
  font-size: 22px;
  min-height: 201px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.inputtext:focus {
  outline-width: 0;
}

.div-translation {
  white-space: pre-wrap;
  font-size: 22px !important;
  min-height: 201px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: rgba(65, 63, 66, 1);
}

.language {
  border-bottom: 1px solid rgba(200, 200, 200, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px !important;
  font-weight: 400;
  font-size: 1.2rem;
  color: rgba(108, 122, 137, 1);
}

.language-latin {
  border-bottom: 1px solid rgba(200, 200, 200, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px !important;
  padding-right: 0px !important;
  font-weight: 400;
  font-size: 1.2rem;
  color: rgba(108, 122, 137, 1);
}

.source-document-mid {
  border-bottom: 1px solid rgba(200, 200, 200, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-weight: 400;
  font-size: 1.2rem;
  color: rgb(47, 52, 57);
}

.source-document {
  border-bottom: 1px solid rgba(200, 200, 200, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px !important;
  font-weight: 400;
  font-size: 1.2rem;
  color: rgb(47, 52, 57);
}

.trash-icon {
  text-align: end;
}

.source,
.target {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.spinner-container {
  text-align: center;
  min-height: 201px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.upload-document {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(200, 200, 200, 1);
  margin: 5px !important;
}

.upload-language {
  border-bottom: 1px solid rgba(239, 239, 239, 1);
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  color: rgba(108, 122, 137, 1);
}

.div-upload,
.div-translate {
  height: 201px;
}

.upload-text-1 {
  padding-top: 5px;
  font-size: 13px;
  color: rgba(108, 122, 137, 1);
}

.upload-text-2 {
  padding-bottom: 5px;
  font-size: 13px;
  color: rgba(108, 122, 137, 1);
}

.drag-text-1 {
  font-size: 15px;
}

.drag-text-2 {
  font-size: 13px;
  color: rgba(196, 77, 86, 1);
}

.file-selected {
  border-radius: 8px;
  margin-top: 0px !important;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: rgba(243, 241, 239, 1);
}

.inprogress-file {
  padding-top: 15px;
  font-size: 15px;
  color: rgba(108, 122, 137, 1);
}

.original-highlight,
.translation-highlight:hover {
  background-color: rgba(184, 241, 176, 1);
}

.german-text {
  background-color: rgb(220, 225, 231);
}

.gutter {

    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    cursor: col-resize;
}

.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}
</style>
