<template>
  <div class="about">
    <NavBar />
    <b-container class="pt-3">
      <AboutDetails />
    </b-container>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import AboutDetails from "@/components/AboutDetails.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    AboutDetails,
    Footer,
  },
};
</script>

<style lang="scss">
.home {
  height: 100vh;
}
</style>
