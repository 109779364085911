<template>
  <div>
    <div class="border-b-8">
      <b-container class="pt-4 pb-4">
        <b-row class="header" align-h="between" align-v="center">
          <b-col cols="auto">
            <b-row>
              <b-col>
                <router-link to="/" class="webservice"> Latein-Deutsch-Übersetzer </router-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="auto">
            <a href="https://www.uzh.ch/cmsssl/de.html">
              <b-img
                right
                :src="require('../assets/uzh_logo_d_pos_web_main.jpg')"
              ></b-img>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <b-container>
        <b-row align-h="end">
          <b-col cols="auto" class="link-label">
            <router-link to="/about" class="about-link">Impressum</router-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,700;1,200;1,400;1,700&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,700;1,200;1,400;1,700&display=swap");

.header {
  font-family: "Source Serif Pro", serif;
  font-size: 2.3rem;
  font-weight: 400;
}

.border-b-8 {
  border-bottom: 8px solid rgba(233, 237, 234, 1);
}

.uzh-logo {
  width: 180px;
  height: auto;
}

a {
  font-family: "Source Serif Pro", serif;
}

a:link {
  text-decoration: none !important;
}

a:visited {
  text-decoration: none !important;
}

a:hover {
  color: rgba(101, 123, 107, 1) !important;
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.about-link {
  color: rgba(143, 163, 149, 1) !important;
}

.webservice {
  color: rgba(101, 123, 107, 1) !important;
  border-bottom: 4px solid rgba(101, 123, 107, 1);
}

.link-label {
  text-align: end;
  font-size: 1.5rem;
}
</style>
