<template>
  <div>
    <b-container>
      <div class="description">
        <p>Dieser Webservice wurde im Rahmen des <a href="https://www.bullinger-digital.ch/">Bullinger Digital</a>
        Projekts entwickelt.</p>
        <p>
        Das Übersetzungsmodell wurde mit Hilfe von <a href="https://github.com/awslabs/sockeye">Sockeye</a> trainiert
        und für die Übersetzung von lateinischen Briefen aus dem Bullinger Korpus optimiert.</p>
        <p>
        Das Modell wird seit Januar 2023 nicht mehr weiterentwickelt.
        </p>

        <p>
        Damit die Alignierung auch für lange Briefe sichtbar bleibt, empfehlen wir, die
        Spaltenbreite zu verändern. Wie das geht, sehen Sie im folgenden Video:

        <div class=video style="padding:50% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/825176485?h=313d46328f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Bullinger Digital-Übersetzer: Spaltenbreite verändern"></iframe></div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AboutDetails",
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,700;1,200;1,400;1,700&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,700;1,200;1,400;1,700&display=swap");

.description {
  padding-left: 20px;
  padding-right: 20px;
}
.video {
    margin: auto;
    padding-bottom: 56.25%;
    width: 70%;
    position: relative;
}
.title {
  font-family: "Source Serif Pro", serif;
  font-size: 1.875rem;
  font-weight: 300;
}
</style>
