import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// textarea-autosize
import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

// Use Notify
import Notify from 'vue2-notify'
Vue.use(Notify, { duration: 400, visibility: 5000, position: 'top-full' })

// Text Highlight
// import TextHighlight from 'vue-text-highlight'
// Vue.component('text-highlight', TextHighlight)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
