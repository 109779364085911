<template>
  <div class="home">
    <NavBar />
    <div>
      <b-container class="pt-3">
        <Translator />
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Translator from "@/components/Translator.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    Translator,
    Footer,
  },
};
</script>

<style lang="scss">
.home {
  min-height: 100% !important;

  display: flex;
  flex-direction: column;
}
</style>
